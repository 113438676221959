<template>
  <div class="container">
    <h3>{{ $t("market.rechargeTips") }}</h3>
    <el-table
      :data="tableData"
      style="width: 100%"
      :empty-text="$t('rankText.emptyText')"
    >
      <el-table-column prop="num" :label="$t('market.rechargeAmount')" align="center">
        <template slot-scope="scope">
          <span>{{ toSelfFixed(scope.row.amount, 2) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="created_at"
        align="center"
        :label="$t('market.time')"
      >
      </el-table-column>
      <el-table-column
        prop="num"
        align="center"
        :label="$t('market.status')"
      >
        <template slot-scope="scope">
          <div class="statusBox">
            <span>{{ rechargeStatusList[scope.row.status] }}</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { toSelfFixed } from "@/utils/common";
import { getRechargelog } from "@/api/user";
import { onMounted, ref } from "vue";
import { useOption } from "@/hooks/useOption";
const { rechargeStatusList } = useOption();
const tableData = ref([]);
onMounted(async () => {
  const { message } = await getRechargelog({ limit: "999999" });
  tableData.value = message.data;
});
</script>

<style lang="scss" scoped>
.container {
  width: 1140px;
  margin: 35px auto;
  h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  :deep(.cell) {
    display: flex;
    justify-content: center;
  }
  .statusBox {
    border-radius: 20px;
    width: 30%;
    text-align: center;
  }
}
</style>
